var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row mx-10"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"},[_vm._v(" All Members ")]),_c('v-btn',{attrs:{"to":{ name: 'admin.customers.new' },"color":"primary mt-2"}},[_c('span',{staticClass:"font font-sm"},[_vm._v("Add New Member")])])],1)])]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end mx-10"},[_c('v-text-field',{staticClass:"box-shadow-light font font-sm",staticStyle:{"min-width":"400px"},attrs:{"solo":"","rounded":"","placeholder":"Search through members using first name or last name ...","flat":"","append-icon":"search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize mx-10",attrs:{"headers":_vm.headers,"items":_vm.allMembers,"loading":_vm.pageLoading,"server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [30, 40, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1 font",attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:"white--text subtitle-1 "},[_vm._v(_vm._s(_vm._f("firstLetterFunction")(item.firstName ? item.firstName : item.lastName)))])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font font-sm"},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.code",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_c('span',{staticClass:" font font-sm"},[_vm._v(_vm._s(item.code))])])]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_c('span',{staticClass:" font font-sm text-capitalize"},[_vm._v(_vm._s(item.address1 ? item.address1 : 'No address available'))])])]}},{key:"item.phoneNo",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_c('span',{staticClass:" font font-sm"},[_vm._v(_vm._s(item.phoneNo ? item.phoneNo : item.mobileAlert))])])]}},{key:"item.accType",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:"font font-sm"},[_vm._v(_vm._s(item.isMicroAccount ? 'Micro Acc.' : 'Main Acc.'))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                  name: 'admin.customers.new',
                  query: {
                    id: item._id,
                    action: 'edit',
                  },
                },"icon":"","fab":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Edit Member Details")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.isLoading,"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.$store.dispatch('members/delete', item._id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Delete Member Details")])])]}}])})],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }